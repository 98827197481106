// ==============================
// Custom style
// 自定义样式
// ==============================

.home {
    .home-profile {  
      .home-avatar {
        img {
          display: inline-block;
          width: 8rem;
          height: auto;
          margin: 0 auto;
          @include border-radius(1%);
          @include box-shadow(0 0 0 .3618em $color-background-alt-dark);
  
          &:hover {
            position: relative;
            @include transform(scale(1.1));
          }

        }
      }
    }
}

#toc-static.toc {
  .toc-title {
    background-color: $color-grey-5-dark !important;
  }
}

#toc-static.open {
  .toc-title {
    background-color: $color-grey-7-dark !important;
  }
}

.logo{
  padding-right: 1.0rem !important;
}


.header-title-post{
  display: inline-block;
  font-weight: $global-font-weight;
  font-size: 1.0rem;
  padding-left: 0.5rem !important;
  ::before{
    content: "";
    display: inline-block;
  }
}

.footer-menu{
  font-size: 1.125rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  .menu-item{
    margin: .5rem;
  }
}

.content-contact {
  padding: 0 0 .5rem;
  text-align: center;
  font-size: 1.5rem;

  .links {
    padding: .5rem;
    font-size: 2.5rem;

    a * {
      vertical-align: text-bottom;
    }

    img {
      height: 1.5rem;
      padding: 0 .25rem;
    }
  }
}

code {
  display:inline-block;
  max-width: 100%;
  padding: 0 .4rem;
  @include overflow-wrap(break-word);
  @include line-break(anywhere);
  font-size: $code-font-size;
  font-family: $code-font-family;
  color: $code-color;

  [theme=dark] & {
    color: $code-color-dark;
  }
}

pre {
  margin: 0;
  padding: .25rem 0 .25rem .5rem;
  @include tab-size(4);

  code {
    padding: 0;
  }

  img, video {
    min-height: 1em;
    max-height: 1.2em;
    vertical-align: text-bottom;
  }
}

.iframe, .iframe iframe {
  max-width: 100%;
  width: 100%;
  height: 30rem;
}

code, pre, .highlight table, .highlight tr, .highlight td {
  background: $code-background-color;

  [theme=dark] & {
    background: $code-background-color-dark;
  }
}

.highlight, .gist {
  font-family: $code-font-family;
  font-size: $code-font-size;

  .table-wrapper {
    > table,
    > table thead,
    > table tr,
    > table td {
      margin: 0;
      padding: 0;
      border: none;
      white-space: nowrap;
    }
  }
}


.highlight {
  line-height: 1.4em;
  margin: .5rem 0;

  > .chroma {
    position: relative;

    .code-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      font-family: $global-font-family;
      font-weight: bold;
      color: $code-info-color;
      background: darken($code-background-color, 8%);

      [theme=dark] & {
        color: $code-info-color-dark;
        background: darken($code-background-color-dark, 6%);
      }

      &:hover {
        cursor: pointer;
      }

      .code-title {
        width: 100%;
        padding: .4rem;
      }

      .code-title::after {
        padding-left: .2rem;
        content: 'Code';
      }

      @each $type, $text in $code-type-map {
        &.#{$type} .code-title::after {
          content: $text;
        }
      }
    }

    .lntd:first-child {
      border-right-width: 1px !important;
      border-right-style: solid !important;
      border-right-color: $color-border-dark !important;
      pre{
      background-color: #212121 !important;
      code{
        background-color: #212121 !important;
        margin-right: 0.75rem;
      }
      }
      min-width: 1.6rem;
    }

    .lntd:last-child {
      width: 100%;

      pre {
        @include max-content(min-width);
      }
    }

    .ln {
      padding-right: .75rem;
    }

    .hl {
      display: block;
      background-color: darken($code-background-color, 10%);

      [theme=dark] & {
        background-color: darken($code-background-color-dark, 5%);
      }
    }

    .ln, .lnt {
      color: $global-font-secondary-color;
      
      text-align: left !important;

      [theme=dark] & {
        color: $global-font-secondary-color-dark;
      }
    }

    .arrow {
      padding: 0 .2rem;
      @include transition(transform 0.2s ease);
    }

    .ellipses {
      padding: .4rem;
    }

    .copy {
      display: none;
      padding: .4rem;

      &:hover {
        cursor: pointer;
        color: $global-link-hover-color;

        [theme=dark] & {
          color: $global-link-hover-color-dark;
        }
      }
    }

    .table-wrapper {
      max-height: 0;
      overflow-y: hidden;
      @include details-transition-open;
    }

    &.open {
      .code-header {
        background: darken($code-background-color, 3%);

        [theme=dark] & {
          background: darken($code-background-color-dark, 3%);
        }
      }

      .table-wrapper {
        max-height: $MAX_LENGTH;
        @include details-transition-close;
      }

      .arrow {
        @include transform(rotate(90deg));
      }

      .ellipses {
        display: none;
      }

      .copy {
        display: inline;
      }
    }
  }

  /* Comment */  .c,
  /* CommentHashbang */  .ch,
  /* CommentMultiline */  .cm,
  /* CommentSingle */  .c1,
  /* CommentSpecial */  .cs,
  /* CommentPreproc */  .cp,
  /* CommentPreprocFile */  .cpf { font-style: italic }
  /* GenericUnderline */  .gl { text-decoration: underline }

  @each $class, $color in $code-highlight-color-map {
    .#{$class} { color: $color; }
  }

  [theme=dark] & {
    @each $class, $color in $code-highlight-color-map-dark {
      .#{$class} { color: $color; }
    }
  }
}

/** CSS for FunctionPlot Graph */
.graph {
  width: 100% !important;

  svg {
    width: 100% !important;
  }

  .function-plot {
    background-color: $color-grey-7-dark;

    text {
      // y axis labels
      color: $color-text-alt-dark;
      font-family: $code-font-family;
      font-size: 0.875rem;
    }

    .x.axis, .y.axis {

      .tick {
        line {
          // grid's vertical lines
          stroke: $color-grey-0-dark;
          opacity: 0.025;
        }
      }

      path.domain {
        // d attribute defines the graph bounds
        stroke: $color-background-alt-dark;
        opacity: 1;
      }
    }


    .content {
      .x.origin, .y.origin {
        stroke: $color-grey-4-dark;
        opacity: 1.0;
      }
    }
  }
}

.plot-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;

  div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
  }

  label {
    white-space: nowrap;
    vertical-align: top;
    padding: 0 1rem 0 1rem;
    line-height: 1.4rem;
  }

  input {
    color: $color-text-dark;
    height: 2.0rem;
    @include border-radius(.2rem);
    border: none;
    outline: none;
    background-color: $color-grey-4-dark;
    vertical-align: baseline !important;
    padding: 0 0.4rem 0 1rem;
    font-family: inherit;
    font-size: 100%;
    margin: .0rem 0.25rem .5rem;

    @include transition(box-shadow 0.3s ease);

    &:focus {
      outline: none;
      @include box-shadow(inset 0 1px 0 $color-highlight-dark);
    }

    [theme=dark] & {
      background-color: $color-grey-4-dark;
    }
  }
}

.fixed-button {
  @include border-radius(0rem);
}

.button {
  display: block;
  width: auto;
  text-align: center;
  right: 1.5rem;
  font-size: 1rem;
  line-height: 1.3rem;
  padding: .6rem 1.6rem;
  color: $global-font-secondary-color;
  background: $header-background-color;
  @include border-radius(0rem);
  @include transition(color 0.4s ease);

  @include blur;

  &:hover, &:active {
    color: $global-font-color;
    cursor: pointer;
  }

  &:active, &:focus, &:hover {
    outline: none;
  }

  [theme=dark] & {
    color: $global-font-secondary-color-dark;
    background: $header-background-color-dark;

    &:hover, &:active {
      color: $global-font-color-dark;
    }
  }
}

.special {
  .single-title, .single-subtitle {
    text-align: left;
  }
}
